// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-administracion-de-personal-js": () => import("./../src/pages/administracion-de-personal.js" /* webpackChunkName: "component---src-pages-administracion-de-personal-js" */),
  "component---src-pages-asesoria-contable-tributaria-js": () => import("./../src/pages/asesoria-contable-tributaria.js" /* webpackChunkName: "component---src-pages-asesoria-contable-tributaria-js" */),
  "component---src-pages-asesoria-legal-js": () => import("./../src/pages/asesoria-legal.js" /* webpackChunkName: "component---src-pages-asesoria-legal-js" */),
  "component---src-pages-desarrollo-e-integracion-organizacional-js": () => import("./../src/pages/desarrollo-e-integracion-organizacional.js" /* webpackChunkName: "component---src-pages-desarrollo-e-integracion-organizacional-js" */),
  "component---src-pages-empleabilidad-y-desarrollo-profesional-js": () => import("./../src/pages/empleabilidad-y-desarrollo-profesional.js" /* webpackChunkName: "component---src-pages-empleabilidad-y-desarrollo-profesional-js" */),
  "component---src-pages-formacion-del-talento-js": () => import("./../src/pages/formacion-del-talento.js" /* webpackChunkName: "component---src-pages-formacion-del-talento-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-salud-mental-js": () => import("./../src/pages/salud-mental.js" /* webpackChunkName: "component---src-pages-salud-mental-js" */),
  "component---src-pages-seleccion-y-headhunting-js": () => import("./../src/pages/seleccion-y-headhunting.js" /* webpackChunkName: "component---src-pages-seleccion-y-headhunting-js" */)
}

