import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({

  // #26BEC9 verde
  // #EC2E5D rosa
  palette: {
    primary: {
      main: '#099ba5',
    },
    secondary: {
      main: '#EC2E5D',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
